<template>

  <div class="global-content">
    <v-skeleton-loader v-if="isLoadingData" type="table"></v-skeleton-loader>

    <v-data-table v-else :headers="headers" :items="paginatedItems" :header-props="{ class: 'custom-header' }"
                  :items-per-page="itemsPerPage">

      <template v-slot:top>
        <div class="filter">
          <v-card class="d-flex justify-start" flat tile>
            <Button @click="refreshProjectList"></Button>

            <DropDownSelect class="dropdown" v-model="selectedProjectTitleList" :items="projectTitleList" label="Projet"
                            multiple @input="handleSelectedProjectTitleListChange">
            </DropDownSelect>

            <DropDownSelect v-if="!user.is_client" class="dropdown" v-model="selectedClients" :items="clientList"
                            label="Client" multiple @input="handleSelectedClientsChange"
                            @click.native="fetchClientList">
            </DropDownSelect>

            <!--          <DropDownSelect v-if="!user.is_client" class="dropdown" v-model="selectedCategories"
                                      :items="categoryList" label="Catégorie" multiple @input="handleSelectedCategoriesChange"
                                      @click.native="fetchCategoryList">
                      </DropDownSelect>-->

          </v-card>
          <button v-if="!user.is_client" id="show-modal" class="add-files" @click="showModal = true"><span
              class="mdi mdi-download-outline"></span>Ajouter des maquettes
          </button>
        </div>
        <div v-if="showModal">
          <UploadFile @close-model="handleCloseModal" @success="handleSuccess"/>
        </div>

        <!-- Success message -->
        <div v-if="successMsg" class="success-msg">
          <v-alert type="success" dismissible @click:close="successMsg = ''">
            {{ successMsg }}
          </v-alert>
        </div>

        <v-divider class=" mt-3 mb-4"></v-divider>
        <v-spacer></v-spacer>
        <v-select v-model="itemsPerPage" :items="numbers" class="number-list"></v-select>

      </template>

      <template v-slot:item="{ item }">
        <tr :class="{ 'selected-row': isSelected(item.id), 'pointer-cursor': true }"
            @click="redirectToDetails(item.id)">
          <td>{{ item.id }}</td>
          <td>
            <div class="image-container-list">
              <v-img v-if="item.image.includes('.')" :src="getImageSource(item.image)" :alt="item.alt"/>
              <div class="icon-image" v-else>
                <!-- Add your icon here -->

                <v-icon>mdi-image-off</v-icon>
              </div>
            </div>
          </td>
          <td>{{ item.title }}</td>
          <td>{{ item.company_name }}</td>
          <!--          <td>{{ item.client_category }}</td>-->
          <td>{{ $filters.formatDate(item.last_activity_at) }}</td>
        </tr>
      </template>

    </v-data-table>

    <template v-if="!isLoadingData && projectList"> <!-- Move Pagination inside v-if -->

      <Pagination :itemsPerPage="itemsPerPage" :totalItems="projectList.length" :currentPage="currentPage"
                  :totalPages="totalPages" :pages="pages" @previous-page="previousPage" @next-page="nextPage"
                  @go-to-page="goToPage"></Pagination>

    </template>
  </div>
</template>

<script>
import Button from '../../components/Button.vue';
import DropDownSelect from '../../components/DropdownSelect.vue';
import Pagination from '../../components/Pagination.vue';
import {useProjectStore} from '../../store/projectStore';
import {useUserStore} from '../../store/userStore'
import {BE_BASE_URL} from '../../config/config';
import axios from 'axios';
import UploadFile from "@/components/UploadFile.vue";

export default {
  components: {
    UploadFile,
    Button,
    DropDownSelect,
    Pagination
  },

  name: "CargaFoto",
  data: () => ({
    el: "#app",
    msg: '',
    file: null,
    files: [], // Array to store multiple files
    isOverDropZone: false,
    showModal: false,
    projectList: [],
    headers: [
      {
        title: 'ID',
        align: 'start',
        sortable: false,
        key: 'id',
      },
      {title: 'Illustration', key: 'image'},
      {title: 'Projet', key: 'title'},
      {title: 'Client', key: 'company_name'},
      //{ title: 'Catégorie', key: 'client_category' },
      {title: 'Dernière m.a.j', key: 'last_activity_at'},

    ],
    // serach filter process
    projectTitleList: [],
    itemsPerPage: 10, // Number of items per page
    currentPage: 0,
    totalPages: 0, // Initialize totalPages
    selectedNumber: 15,
    numbers: [5, 10, 15, 20, 50, 100],
    selectedProjectTitleList: [],
    selectedClients: [],
    // selectedCategories: [],
    isLoadingData: true, // Initially set to true to display skeleton loader
    clientList: [],
    categoryList: [],
    newSelectedValues: []

  }),
  created() {
    const userStore = useUserStore()
    this.user = userStore.user
  },
  mounted() {
    document.title = "Creation Projets - STRATIS";
    this.fetchProjectList()
    this.searchProjects()
    this.fetchClientList()
    this.fetchCategoryList()

  },
  computed: {
    paginatedItems() {
      const startIndex = this.currentPage * this.itemsPerPage;
      return this.projectList.slice(startIndex, startIndex + this.itemsPerPage);
    },
    pages() {
      this.totalPages = Math.ceil(this.projectList.length / this.itemsPerPage);
      return Array.from({length: this.totalPages}, (_, i) => i + 1);
    },
    concatenatedFilters() {
      return [
        ...this.selectedProjectTitleList,
        ...this.selectedClients,
        //    ...this.selectedCategories
      ];
    },
    isSelected() {
      const projectStore = useProjectStore();
      return (id) => projectStore.selectedRow === id;
    }
  },


  methods: {

    handleCloseModal(isClosed) {
      this.showModal = isClosed;
      this.fetchProjectDetails();
      this.updatedHeaders();
    },
    handleSuccess(msg) {
      this.successMsg = msg;

      // Optional: Clear the success message after a timeout
      setTimeout(() => {
        this.successMsg = '';
      }, 5000); // Clear message after 5 seconds
    },
    getImageSource(imagePath) {
      const URLPath = 'uploads/projects/previews/';
      if (imagePath.includes(URLPath)) {
        return `${BE_BASE_URL}${imagePath}`;
      } else {
        return `${BE_BASE_URL}${URLPath}${imagePath}`;
      }
    },

    redirectToDetails(recordId) {
      const projectStore = useProjectStore()
      projectStore.setSelectedRow(recordId);

      this.$router.push({name: 'ProjectDetails', params: {id: recordId}});
    },

    removeChip(item) {

      // Check and remove from selectedProjectTitleList
      const selectedProjectIndex = this.selectedProjectTitleList.indexOf(item);


      if (selectedProjectIndex !== -1) {
        for (let key in this.selectedProjectTitleList) {
          if (this.selectedProjectTitleList[key] === item) {
            delete this.selectedProjectTitleList[key];
            break;
          }
        }
      }

      // Check and remove from selectedClients
      const selectedClientIndex = this.selectedClients.indexOf(item);
      if (selectedClientIndex !== -1) {
        for (let key in this.selectedClients) {
          if (this.selectedClients[key] === item) {
            delete this.selectedClients[key];
            break;
          }
        }
      }

      // Check and remove from selectedCategories
      /* const selectedCategoryIndex = this.selectedCategories.indexOf(item);
       if (selectedCategoryIndex !== -1) {
         for (let key in this.selectedCategories) {
           if (this.selectedCategories[key] === item) {
             delete this.selectedCategories[key];
             break;
           }
         }
       }*/

    },

    refreshProjectList() {
      this.searchProjects()
      this.currentPage = 0
      this.clearFilters()
    },
    async searchProjects() {
      try {
        const requestData = {
          projectNames: this.selectedProjectTitleList.filter(name => name !== null),
          clientNames: this.selectedClients.filter(name => name !== null),
          //  clientCategories: this.selectedCategories.filter(name => name !== null)
        };
        const {data} = await this.axios.post('/project/search-projects', requestData);
        this.projectList = data;
        this.currentPage = 0;
        // this.isLoadingData = true;
      } catch (error) {
        console.error('Error searching projects:', error);
      }
    },
    // // fetch project list
    async fetchProjectList() {
      try {
        const response = await this.axios.get('project');
        this.projectList = response.data;
        this.projectTitleList = this.projectList.map(project => ({id: project.id, title: project.title}));
        this.isLoadingData = false;

      } catch (error) {
        console.error('Error fetching project list data:', error);
      }
    },

    async fetchClientList() {
      try {
        const {data} = await this.axios.get('project/company-names');
        this.clientList = data.map(({clientId, companyName}) => ({id: clientId, title: companyName}));
      } catch (error) {
        console.error('Error fetching project list data:', error);
      }
    },
    // fetch category list
    async fetchCategoryList() {
      try {
        const {data} = await this.axios.get('project/client-categories');
        this.categoryList = data.map(({clientCategory}) => ({title: clientCategory}));
      } catch (error) {
        console.error('Error fetching project list data:', error);
      }
    },

    previousPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    goToPage(page) {
      if (page - 1 !== this.currentPage) {
        // If not, update the current page
        this.currentPage = page - 1;
      }

    },

    handleSelectedProjectTitleListChange(newSelectedValues) {
      this.selectedProjectTitleList = newSelectedValues;
      this.searchProjects();
    },

    handleSelectedClientsChange(newSelectedValues) {
      this.selectedClients = newSelectedValues;
      this.searchProjects();
    },

    /*  handleSelectedCategoriesChange(newSelectedValues) {
        this.selectedCategories = newSelectedValues;
        this.searchProjects();
      },*/

    clearFilters() {
      this.selectedProjectTitleList = [];
      this.selectedClients = [];
      //  this.selectedCategories = [];
      this.concatenatedFilters = [];
      //this.searchProjects();
    },
    // Trigger file input when the dropzone is clicked

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // Handle file selection via input
    handleFileSelection(event) {
      const selectedFiles = Array.from(event.target.files);
      this.files.push(...selectedFiles);
    },

    // Handle file drop into the dropzone
    handleFileDrop(event) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      this.files.push(...droppedFiles);
      this.isOverDropZone = false; // Reset "is-over" after drop
    },

    // Handle drag over event to show visual effect
    handleDragOver() {
      this.isOverDropZone = true;
    },

    // Handle drag leave event to hide the effect
    handleDragLeave() {
      this.isOverDropZone = false;
    },

    // Remove file from the list
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async submitFiles() {
      if (this.files.length === 0) {
        console.log('No files selected');
        return;
      }

      let formData = new FormData();
      this.files.forEach((file, index) => {
        formData.append(`file_${index}`, file);
      });

      try {
        const response = await axios.post('/file/jsonimagen/', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        });

        // Display a success message from API response if available, else use a default success message
        this.msg = response.data.message || 'Files successfully uploaded';

        console.log(this.msg); // Log success message
        this.removeFile(); // Clear selected files
        this.refreshProjectList(); // Refresh the project list
        this.showModal = false; // Close the modal if needed
        this.msg = '';
      } catch (error) {
        console.error('Error uploading files:', error);

        // Set error message from API response or fallback message
        this.msg = error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : 'Error uploading files. Please try again.';
        this.removeFile(); // Clear selected files
      }
    },
  },

}
</script>

<style lang="scss">
@mixin button-style {
  text-align: left;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #494949;
  background: #F7F4F4 !important;

}

@mixin text-style {
  opacity: 1;
  text-align: left;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;

}


.global-content {
  padding: 1rem !important;
}

.toolbar-list .v-toolbar__content {
  background: white;
}

.custom-header {
  background: #30322F 0% 0% no-repeat padding-box;

  color: white;

  :hover {
    color: #d0d0d0;
  }
}


.filter-actif {
  @include text-style;
  color: black;
}

.v-field__field .select-options {
  height: 20px;
  width: 15%;
}

.custom-height-select .v-input__control {
  margin-left: 2px;
  margin-right: 2px;
  height: 40px;
}

.v-data-table-footer {
  display: none !important;

}


.pagination-number {
  // @include button-style;
  color: #494949;
  background: white !important;
  width: 7px !important;
  height: 18px;
}

.selected-page-button {
  background-color: #C90D0F !important;
  color: #fff !important;
}


.dropdown .v-select .v-input__control {
  height: 40px;
  width: 150px;
  border-radius: 5px;
  @include button-style;
}

// dropdown select start
.v-select .v-input__control {
  height: 40px;
  width: 160px;
  border-radius: 5px;
  @include button-style;
}

.v-field-label {
  padding-bottom: 1rem !important;
  color: #494949 !important;
  text-align: center !important;;
}

//icon v-field
.v-field__append-inner {
  padding-bottom: 1rem !important;

}

.select-filter .v-select__selection {
  display: none !important
}


// dropdown select end


// remove the shadow of the card used with select options
.v-card--variant-elevated {

  box-shadow: none !important;
}

.chips {
  @include text-style;
  background: #C90D0F 0% 0% no-repeat padding-box !important;
  color: white !important;
  border-radius: 3px;
  margin-bottom: 10px
}

.selected-row {
  background-color: #CBF8D1;
}

.pointer-cursor {
  cursor: pointer;
}

.image-container-list {
  margin: 7px;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  background: #DFEBF3 0% 0% no-repeat padding-box;
}

.image-container-list img {
  width: 100%;
  height: auto !important;
  object-fit: cover;
}

.icon-image i {
  color: #05A9F4;
}

// .dropzone-area {
//   padding: 20px;
//   border: 2px dashed #ccc;
//   text-align: center;
//   cursor: pointer;
//   transition: background-color 0.3s;
// }
// .is-over {
//   background-color: #f0f8ff;
// }
.dropzone-area {
  border: 2px dashed #C90D0F;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.is-over {
  border-color: #4caf50;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

button {
  background-color: #C90D0F;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  color: #C90D0F;
}

button.submit-button {
  margin-top: 8px;
  margin-right: 5px;
  background-color: #f1f1f1;
  color: black;
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

button.submit-button:hover {
  background-color: #C90D0F;
  color: white;
}

button#show-modal {
  position: relative;
  height: max-content;
  background-color: #f1f1f1;
  color: black;
  font-size: 14px;
  padding: 14px 30px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

button#show-modal span {
  background: #C90D0F 0% 0% no-repeat padding-box;
  padding: 7px;
  border-radius: 50%;
  font-size: 18px;
  color: white;
  position: absolute;
  left: -18px;
  top: 4px;
  height: 40px;
  width: 40px;
}

button#show-modal:hover {
  background-color: #d1d1d1;
  color: black;
}

button.close-button {
  margin-top: 8px;
  background-color: #f1f1f1;
  color: black;
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

button.close-button:hover {
  background-color: #C90D0F;
  color: white;
}

.filter {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 14px 7px;
}

.popup .title-popup {
  padding: 9px 8px;
  font-size: 14px;
  color: #C90D0F;
}

.popup {
  background-color: #f1f1f1;
  border: 1px solid #F7F4F4;
  border-radius: 5px;
}
</style>
